<template>
    <div class="container_con">
        <el-card v-if="form" >
            <!-- <div class="con_title"><span></span> 采购计划</div>
            <div style="line-height: 24px; font-size: 14px;">
                <el-row :gutter="20" style="margin-bottom: 10px;">
                    <el-col :span="6">开票日期：{{purchase_plan.purchase_plan_date}}</el-col>
                    <el-col :span="6">联系电话：{{purchase_plan.merchant.mebl_operation_mode}}</el-col>
                    <el-col :span="6">部门：{{purchase_plan.department_name}}</el-col>
                    <el-col :span="6">供货单位：{{purchase_plan.merchant.merchant_name}}</el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6">单据编号：{{purchase_plan.purchase_plan_no}}</el-col>
                    <el-col :span="6">单位编号：{{purchase_plan.merchant.merchant_no}}</el-col>
                    <el-col :span="6">采购员：{{purchase_plan.admin_name}}</el-col>
                </el-row>
            </div> -->
            
            <!-- <div class="" style="margin-top: 10px; font-size: 14px;">
                <div style="padding-bottom: 20px;">采购产品：</div>
                <div class="">
                    <el-table :data="products" style="width: 100%" >
                      <el-table-column label="产品编号">
                          <template v-slot="scope">
                            {{scope.row.product_no}}
                          </template>
                      </el-table-column>
                      <el-table-column label="品名">
                          <template v-slot="scope">
                            {{scope.row.product_name}}
                          </template>
                      </el-table-column>
                      <el-table-column label="助记码">
                          <template v-slot="scope">
                            {{scope.row.mnemonic_code}}
                          </template>
                      </el-table-column>
                      <el-table-column label="型号规格">
                          <template v-slot="scope">
                            {{scope.row.model}}
                          </template>
                      </el-table-column>
                      <el-table-column label="生产厂家">
                          <template v-slot="scope">
                            {{scope.row.manufacturer}}
                          </template>
                      </el-table-column>
                    </el-table>
                </div>
            </div> -->
            
            <!-- 
            <div class="con_title" ><span></span> 验收信息</div>
            <div style="line-height: 24px; font-size: 14px;">
                <el-row :gutter="20" style="margin-bottom: 10px;">
                    <el-col :span="6">开票日期：{{purchase_acceptance1.purchase_acceptance_date}}</el-col>
                    <el-col :span="6">单据编号：{{purchase_acceptance1.purchase_acceptance_no}}</el-col>
                    <el-col :span="6">产品批号：{{purchase_acceptance2.batch_number}}</el-col>
                    
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px;">
                    <el-col :span="6">验收员：{{purchase_acceptance1.inspector_name}}</el-col>
                    <el-col :span="6">有效期至：{{purchase_acceptance2.valid_until || '长效期'}}</el-col>
                    <el-col :span="6">验收数量：{{purchase_acceptance2.product_num}}</el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px;">
                    <el-col :span="24">验收结论：{{purchase_acceptance2.acceptance_conclusion}}</el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px;">
                    <el-col :span="24">质量情况：{{purchase_acceptance2.quality_situation}}</el-col>
                </el-row>
            </div>
            -->
            
            <div class="con_title" ><span></span> 入库信息</div>
            <div style="line-height: 24px; font-size: 14px;">
                <el-row :gutter="20" style="margin-bottom: 10px;">
                    <el-col :span="6">开票日期：{{form.purchase_storage_date}}</el-col>
                    <el-col :span="6">单据编号：{{form.purchase_storage_no}}</el-col>
                    <el-col :span="6">保管员：{{form.keeper_name}}</el-col>
                    <el-col :span="6">备注：{{form.remark}}</el-col>
                    <!-- <el-col :span="6">仓库货架：{{form.purchase_storage_product[0].warehouse_name}}</el-col> -->
                </el-row>
                
                <div class="" style="margin-top: 10px; font-size: 14px;">
                    <div style="padding-bottom: 20px;">入库产品：</div>
                    <div class="">
                        <el-table :data="list" style="width: 100%" >
                          <el-table-column label="产品编号">
                              <template v-slot="scope">
                                {{scope.row.purchase_acceptance_product.purchase_plan_product.product.product_no}}
                              </template>
                          </el-table-column>
                          <el-table-column label="品名">
                              <template v-slot="scope">
                                {{scope.row.purchase_acceptance_product.purchase_plan_product.product.product_name}}
                              </template>
                          </el-table-column>
                          <el-table-column label="货位名称">
                              <template v-slot="scope">
                                {{scope.row.warehouse_name}}
                              </template>
                          </el-table-column>
                          <el-table-column label="型号规格">
                              <template v-slot="scope">
                                {{scope.row.purchase_acceptance_product.purchase_plan_product.product.model}}
                              </template>
                          </el-table-column>
                          <el-table-column label="生产厂家">
                              <template v-slot="scope">
                                {{scope.row.purchase_acceptance_product.purchase_plan_product.product.manufacturer}}
                              </template>
                          </el-table-column>
                          <el-table-column label="单位">
                              <template v-slot="scope">
                                {{scope.row.pack_unit_name}}
                              </template>
                          </el-table-column>
                          <el-table-column label="数量">
                              <template v-slot="scope">
                                {{scope.row.product_num}}
                              </template>
                          </el-table-column>
                          <el-table-column label="单价">
                              <template v-slot="scope">
                                {{scope.row.product_price}}
                              </template>
                          </el-table-column>
                          <el-table-column label="金额">
                              <template v-slot="scope">
                                {{(parseFloat(scope.row.product_price)*parseFloat(scope.row.product_num) || 0).toFixed(2)}}
                              </template>
                          </el-table-column>
                          <el-table-column label="产品批号">
                              <template v-slot="scope">
                                {{scope.row.batch_number}}
                              </template>
                          </el-table-column>
                          <el-table-column label="有效期至">
                              <template v-slot="scope">
                                {{scope.row.valid_until || '长效期'}}
                              </template>
                          </el-table-column>
                          <el-table-column label="注册证名">
                              <template v-slot="scope">
                                {{scope.row.purchase_acceptance_product.purchase_plan_product.product.register_cert_name}}
                              </template>
                          </el-table-column>
                          <el-table-column label="注册证号">
                              <template v-slot="scope">
                                {{scope.row.purchase_acceptance_product.purchase_plan_product.product.register_cert_no}}
                              </template>
                          </el-table-column>
                          <el-table-column label="通知业务员">
                              <template v-slot="scope">
                                {{scope.row.salesman.username}}
                              </template>
                          </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="bottom_btn" style="justify-content: flex-start;">
                <el-button @click="$router.go(-1)">返回</el-button>
            </div>
        </el-card>
        <!-- 上传图片预览 -->
        <el-dialog :close-on-click-modal="false" title="图片预览" width="600px" v-model="uploadImgVisible">
            <img style="width: 100%;" :src="uploadImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
    // 上传组件
    export default {
        name: "productDetail",
        data() {
            return {
                purchase_plan:'',
                purchase_acceptance1:'',
                purchase_acceptance2:'',
                products:[],
                form: '',
                // 上传图片
                uploadImageUrl: '',
                uploadImgVisible: false,
            };
        },
        created() {
            this.id = this.$route.query.id;
            this.get_data()
        },
        methods: {
            PicturePreview(file) {
                this.uploadImageUrl = file.url;
                this.uploadImgVisible = true;
            },
            get_data() {
                this.$httpGet("/backend/PurchaseStorage/read", {
                    id: this.id
                }).then((res) => {
                    if (res.status == 200) {
                        let _data = res.data;
                        
                        this.list=res.data.purchase_storage_product;
                        
                        // this.products=[_data.purchase_storage_product[0].purchase_acceptance_product.purchase_plan_product.product]
                        this.purchase_plan=_data.purchase_acceptance.purchase_plan;
                        // this.purchase_acceptance1=_data.purchase_acceptance;
                        // this.purchase_acceptance2=_data.purchase_storage_product[0].purchase_acceptance_product;
                        this.form = _data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .items {
        width: 100%;
        padding-right: 30px;
    }

    .items .item {
        padding: 10px;
        width: 100%;
        padding-right: 30px;
        line-height: 24px;
        color: #333;
        font-size: 14px;
    }

    .items .item .tt {
        color: #888;
        width: 150px
    }

    .avatar {
        height: 80px;
    }
</style>
